import React, { memo, useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { Skeleton } from '@mui/material';

import rogo from '../IDnest.jpeg'
import { useTranslation } from 'react-i18next';


const NewSidebar = memo(({userImageUrl,imageKey}) => {
  const renderCount = useRef(0);
    const [userimg,setuserimg] = useState([]);
    const [loading,setloading] = useState(true);
    //言語設定
    const { t,i18n } = useTranslation();

    const localuuid = localStorage.getItem('login');





  return (
    <div>
        <>
            <div className='Sidebar'>
            <ul className='submenu'>
              <div className='rogo'>
                {/* <Skeleton className='appicon' variant="circular" width={40} height={40} /> */}
                <img className='sidebar_rogo' src={rogo}/>
              </div>
              <div className='sideoption'>
                {/* <li className='home_home_icon' key={'home'}><Link className='side homeside' to='/home' ><HomeIcon className='homeicon i'/><span className='sideber_span'>ホーム</span></Link></li>
                <li className='home_profile_icon' key={'profile'}><Link className='side profileside' to={`/profile/${pk}`} ><AccountCircleIcon className='profileicon i'/><span className='sideber_span'>プロフィール</span></Link></li>
                <li className='home_search_icon' key={'search'}><Link className='side searchside' to='/search' ><SearchIcon className='searchicon i'/><span className='sideber_span'>検索</span></Link></li>
                <li className='home_setting_icon' key={'setting'}><Link className='side settingside' to='/setting' ><SettingsIcon className='settinigicon i'/><span className='sideber_span'>設定</span></Link></li>
                <li className='home_user_icon' key={'img'}><img className='user_img' src={userimg}/></li> */}
                <li className='home_home_icon' key={'home'}>
                  <Link className='side homeside' to='/Home'>
                    <div className='side_text'>
                      <HomeIcon className='homeicon i'/>
                      <div className='sideber_span'>{t('home')}</div>
                    </div>
                  </Link>
                </li>
                <li className='home_profile_icon' key={'profile'}>
                  <Link className='side profileside' to={`/Profile/${localuuid}`}>
                    <div className='side_text'>
                      <AccountCircleIcon className='profileicon i'/>
                      <div className='sideber_span'>{t('profile')}</div>
                    </div>
                  </Link>
                </li>
                <li className='home_search_icon' key={'search'}>
                  <Link className='side searchside' to='/Search'>
                    <div className='side_text'>
                      <SearchIcon className='searchicon i'/>
                      <div className='sideber_span'>{t('search')}</div>
                    </div>
                  </Link>
                </li>
                <li className='home_setting_icon' key={'setting'}>
                  <Link className='side settingside' to='/Setting'>
                    <div className='side_text'>
                      <SettingsIcon className='settinigicon i'/>
                      <div className='sideber_span'>{t('setting')}</div>
                    </div>
                  </Link>
                </li>
                  <li className='home_user_icon' key={imageKey}><img className='user_img' src={userImageUrl}/></li>
              </div>
            </ul>
        </div>
        <div className='mobile_side'>
          <div className='mobile_sideoption'>
            <li className='home_li' key={'home'}><Link className='side homeside' to='/Home' ><HomeIcon className='homeicon i'/><span className='sideber_span'></span></Link></li>
            <li className='home_li' key={'profile'}><Link className='side profileside' to={`/Profile/${localuuid}`} ><AccountCircleIcon className='profileicon i'/><span className='sideber_span'></span></Link></li>
            <li className='home_li' key={'search'}><Link className='side searchside' to='/Search' ><SearchIcon className='searchicon i'/><span className='sideber_span'></span></Link></li>
            <li className='home_li' key={'setting'}><Link className='side settingside' to='/Setting' ><SettingsIcon className='settinigicon i'/><span className='sideber_span'></span></Link></li>
          </div>
        </div>
        </>
    </div>
  )
});

export default NewSidebar;