import { arrayRemove, arrayUnion, doc, runTransaction, updateDoc } from 'firebase/firestore';
import React  from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { db } from '../../firebase';

//tab
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import '../Newcss/NewSidebar.css';
import '../Newcss/NewHome.css'
import { useTranslation } from 'react-i18next';
import NewSidebar from './NewSidebar';
import { Helmet } from 'react-helmet-async';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function NewHome(
    {friend_user,
    follow_user,
    follower_user,
    followingImages,
    followerImages,
    friendimg,
    followinginfo,
    followerinfo,
    friendinfo,
    follow_follower_friend_ID,
    userImageUrl,
    imageKey,
    UserInfo}){
      const localuuid = localStorage.getItem('login');
      localStorage.setItem('page','home');

      const navigate = useNavigate();
      //言語設定
    const { t } = useTranslation();
  
  
      if(localuuid === null){
        const remove_app = () => {
          navigate('/');
        }
        remove_app()
      }

      const [value, setValue] = React.useState(0);

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };
//ユーザーがいるかどうか
      const Frienduserlength = () => {
        if (friend_user.length === 0){
          return(
            <div className='newhome_nouser'>
            <h3>{t('not_found_user')}</h3>
            </div>
          )
        }
      }
      const Followuserlength = () => {
        if (follow_user.length === 0){
          return(
            <div className='newhome_nouser'>
            <h3>{t('not_found_user')}</h3>
            </div>
          )
        }
      }
      const Followeruserlength = () => {
        if (follower_user.length === 0){
          return(
            <div className='newhome_nouser'>
            <h3>{t('not_found_user')}</h3>
            </div>
          )
        }
      }

  //フォロー解除================================
  const cancel_following = async(event,another_uuid) => {
    event.preventDefault();
    //フォロー側（相手に通知＆自分のリストに）
    const followRef = doc(db, "Friends", localuuid);
    const followerRef = doc(db, "Friends", another_uuid);
  
    try {
      await runTransaction(db, async (transaction) => {
        // followingリストにanother_uuidを追加
        transaction.update(followRef, {
          following: arrayRemove(another_uuid)
        });
        console.log('自分フォロー追加')
  
        // followerリストにlocaluuidを追加
        transaction.update(followerRef, {
          follower: arrayRemove(localuuid)
        });
        console.log('フォロー完了')
      });
  
      console.log("Transaction successfully committed!");
    } catch (e) {
      console.log("Transaction failed: ", e);
    }

    console.log('フォロー解除')
    };

  //フォロー======================================
  const following_action = async(event,another_uuid) =>{
    event.preventDefault();
    //フォロー側（相手に通知＆自分のリストに）
    const followRef = doc(db, "Friends", localuuid);
    await updateDoc(followRef, {
      follower: arrayRemove(another_uuid),
      friend: arrayUnion(another_uuid)
  });

    //フォロワー側
    const followerRef = doc(db, "Friends", another_uuid);
    await updateDoc(followerRef, {
      following: arrayRemove(localuuid),
      friend: arrayUnion(localuuid)
  });
  };
  const Displaysidebar = () => {
    return (
      <NewSidebar
      userImageUrl={userImageUrl}
      key={imageKey}
    />)
  }
        
  return (
    <div  className='app'> 
      <Helmet>
        <title>IDNect-Home</title>
      </Helmet>

      <div className='app_sidebar'>
        <Displaysidebar/>
      </div>
      <style>
      {`
        .homeside {
          font-weight: bold;
          background-color: rgb(231, 231, 231);
        }
      `}
      </style>
    <div className='NewHome_body'>
          <h3 className='pagetitle' >{t("home")}</h3>
    <div>
    {UserInfo.map((user,index) => (
            <div key={user.uuid+index}>
            <div className='newprofile_userinfo'>
            <div className='newprofile_userinfo_text' key={index}>
              <img  className='newprofile_userimage'  alt='' src={userImageUrl} />
              <div className='newprofile_editprofile'>

              </div>
            </div>
            <div>
            <h1 className='newprofile_username'>{user.Username}</h1>
            <p className='newprofile_userid'>@{user.UserID}</p>
            </div>
            </div>
            </div>
          ))}
    <Box sx={{ width: '100%'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
          <Tab label={t("friend")} {...a11yProps(0)} />
          <Tab label={t("follow")} {...a11yProps(1)} />
          <Tab label={t("follower")} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Frienduserlength/>
    {friendinfo.map((user,index) => (
    <div key={user.Username+'_'+user.uuid} className='usersinfo'>
        {/* PCでの表示 */}
        <Link to={`/Profile/${user.uuid}`} >
        <div  className='friend_search_result' >
          <img  className='home_user_img' alt='' src={friendimg[index]} />
          <div className='username' >
            {user.Username}
            </div>
          {/* <button onClick={(event) => delete_friend(event,user.uuid)}>フレンドを削除</button> */}
        </div>
        </Link>

        {/* //スマホ表示 */}
        {/* <Link to={`/NewProfile/${user.uuid}`} >
        <div  className='friend_search_result_mobile'>
          <div><img  className='home_user_img' src={friendimg[index]} /></div>
          <div className='username' >{user.Username}</div>
        </div>
        </Link> */}
    </div>
  ))}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} sx={{Padding: 0}}>
        <Followuserlength/>
    {followinginfo.map((user,index) => (
    <div key={user.Username+'_'+user.uuid} className='usersinfo'>
      <Link to={`/Profile/${user.uuid}`} >
        <div  className='friend_search_result' >
            <img  className='home_user_img'  alt='' src={followingImages[index]} />
            <div className='username' >{user.Username}</div>
            <div className='new_profile_follow_deletebutton'>
            <button className='home_follow_delete_button' onClick={(event) => cancel_following(event,user.uuid)}>{t("cancel_follow")}</button>
            </div>
        </div>
      </Link>
    </div>
    ))}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <Followeruserlength/>
    {followerinfo.map((user,index) => (
      <div key={user.Username+'_'+user.uuid} className='usersinfo'>
      <Link to={`/Profile/${user.uuid}`} >
        <div  className='friend_search_result' >
          <img  className='home_user_img' alt='' src={followerImages[index]} />
          <div className='username' >{user.Username}</div>
          <div className='new_profile_follow_deletebutton'>
            <button className='home_follow_delete_button' onClick={(event) => following_action(event,user.uuid)}>{t("do_follow")}</button>
          </div>
        </div>
      </Link>
    </div>
    ))}
      </CustomTabPanel>
    </Box>
    
    </div>
    </div>
    </div>
  )
}

export default NewHome