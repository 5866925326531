import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import {  collection, doc, getDocs, limit, query, updateDoc, where } from 'firebase/firestore';
import Cropimg from './crop_img';
import './edit_profile.css';


//sns
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faDiscord, faTwitch, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import { getStorage, ref, uploadBytes } from 'firebase/storage';


function Editprofilebutton({imgurl,username,userid,introduction,updateState,sns_list,handleImageChange}) {
  const [open, setOpen] = React.useState(false);
  const [name,setname] = useState();
  const [userID,setuserid] = useState();
  const [Introduction,setIntroduction] = useState();
  const [sns,setsns] = useState();
  const [sameuser,set_sameuser] = useState();
  const [edit_profile_img,set_edit_profile_img] = useState();
  const [up_img,setup_img] = useState();


  const [check_userid,setcheck_userid] = useState();

  const [user] = useAuthState(auth);

  const { t} = useTranslation();




  //SNS
  const [x,setx] = useState();
  const [discord,setdiscord] = useState();
  const [twitch,settwitch] = useState();
  const [youtube,setyoutube] = useState();
  const [tiktok,settiktok] = useState();



  useEffect(() => {
    setname(username);
    setuserid(userid);
    setIntroduction(introduction);
    setsns(sns_list);
    setcheck_userid(userid);
    set_edit_profile_img(imgurl)

  },[username,userid,sns_list,imgurl,introduction])
//画像変更

  useEffect(() => {
    try{
    if (sns){
      setx(sns['X'])
      setdiscord(sns['Discord'])
      setyoutube(sns['Youtube'])
      settwitch(sns['Twitch'])
      settiktok(sns['Tiktok'])
    }
    }catch(error){
      console.log('era-')
    }


  },[sns_list,sns])



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //データ更新===================================
const handleUpdate = async(event) => {
  event.preventDefault();
  if(userID !== check_userid){
    const check_id_q = query(collection(db, "UserInfo"), where("UserID", "==", userID), limit(1));
    const querySnapshot = await getDocs(check_id_q);
    
    for (const doc of querySnapshot.docs) {
      console.log(doc.data());
      if (doc.exists()) {
        set_sameuser('このIDは使用されています');
        return;
      }
    }
  }else{
    set_sameuser('');
  }





  if (user){
    const UserInfo_docRef = doc(db, "UserInfo", user.uid);
    const SNS_docRef = doc(db, "SNS", user.uid);

    const user_data = {
      'Username':name,
      "Username_lower":name.toLowerCase(),
      // 'UserID':userID,
      "UserID_lower":userID.toLowerCase(),
      'introduction':Introduction,
      'uuid':user.uid,
    }

    const sns_data = {
      'Discord':discord,
      'Tiktok':tiktok,
      'Twitch':twitch,
      'X':x,
      'Youtube':youtube,
    }

    await updateDoc((UserInfo_docRef),user_data);
    await updateDoc((SNS_docRef),sns_data);

    if(up_img){
      const storage = getStorage();
      const storageRef = ref(storage, 'user_images/' + user.uid + '.jpg');
      
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, up_img).then((snapshot) => {
          handleImageChange()
      });
    }



    setOpen(false);
  };
  }



  return (
  <React.Fragment>
      <div className='profile_change_button'>
          <span className='edit_profile_button' onClick={handleClickOpen}>
          {t("edit_profile")}
          </span>
      </div>
      <Dialog
          open={open}
          onClose={handleClose}
          // PaperProps={{
          //     component: 'form',
          //     onSubmit: handleSubmit,
          // }}
      >
          <DialogTitle>{t("edit_profile")}</DialogTitle>
          <DialogContent>
              <DialogContentText>
              </DialogContentText>
              <Cropimg className='edit_profile_crop_img' imgurl={edit_profile_img} updateState={updateState} handleImageChange={handleImageChange} set_edit_profile_img={set_edit_profile_img} setup_img={setup_img}/>
              <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  name="name"
                  label={t("name")}
                  type="text"
                  fullWidth
                  variant="standard"
                  value={name}
                  onChange={(event) => setname(event.target.value)}
                  inputProps={{ maxLength: 50 }}
              />
              {/* <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="userID"
                  name="userID"
                  label="ID"
                  type="text"
                  fullWidth
                  pattern="[A-Za-z0-9]*"
                   title="半角英数記号のみを入力してください"
                  variant="standard"
                  value={userID}
                  onChange={(event) => setuserid(event.target.value)}
                  inputProps={{ maxLength: 50 }}
              /> */}
              {sameuser}
              <TextField
                  autoFocus
                  multiline
                  rows={3}
                  margin="dense"
                  id="Introduction"
                  name="Introduction"
                  label={t("introduction")}
                  type="text"
                  fullWidth
                  variant="standard"
                  value={Introduction}
                  onChange={(event) => setIntroduction(event.target.value)}
                  inputProps={{ maxLength: 150 }}
              />
              <div className='sns_div'>
                  <FontAwesomeIcon className='icon x-twitter' icon={faXTwitter} />
                  <TextField
                      autoFocus
                      margin="dense"
                      id="x"
                      name="x"
                      label="https://x.com/"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={x}
                      onChange={(event) => setx(event.target.value)}
                  />
              </div>
              <div className='sns_div'>
                  <FontAwesomeIcon className='icon discord' icon={faDiscord} />
                  <TextField
                      autoFocus
                      margin="dense"
                      id="discord"
                      name="discord"
                      label="userID"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={discord}
                      onChange={(event) => setdiscord(event.target.value)}
                  />
              </div>
              <div className='sns_div'>
                  <FontAwesomeIcon className='icon twitch' icon={faTwitch} />
                  <TextField
                      autoFocus
                      margin="dense"
                      id="twitch"
                      name="twitch"
                      label="https://www.twitch.tv/"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={twitch}
                      onChange={(event) => settwitch(event.target.value)}
                  />
              </div>
              <div className='sns_div'>
                  <FontAwesomeIcon className='icon youtube' icon={faYoutube} />
                  <TextField
                      autoFocus
                      margin="dense"
                      id="youtube"
                      name="youtube"
                      label="https://www.youtube.com/channel/"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={youtube}
                      onChange={(event) => setyoutube(event.target.value)}
                  />
              </div>
              <div className='sns_div'>
                  <FontAwesomeIcon className='icon tiktok' icon={faTiktok} />
                  <TextField
                      autoFocus
                      margin="dense"
                      id="tiktok"
                      name="tiktok"
                      label="https://www.tiktok.com/"
                      type="text"
                      fullWidth
                      variant="standard"
                      value={tiktok}
                      onChange={(event) => settiktok(event.target.value)}
                  />
              </div>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose} type='button'>Cancel</Button>
              <Button onClick={handleUpdate}>{t("save")}</Button>
          </DialogActions>
      </Dialog>
  </React.Fragment>
  )
}

export default Editprofilebutton;