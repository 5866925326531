import React, { useState } from 'react'
import { Button, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';

//icon
import EditIcon from '@mui/icons-material/Edit';
import { t } from 'i18next';

//登録ゲーム編集

function Editbutton({ document_id,uid, profile_gamename, profile_gameusername, profile_gameuserid,gameimg,memo,menuhandleClose }) {
    const [open, setOpen] = React.useState(false);
    const [gamename,setgamename] = useState(profile_gamename);
    const [gameuserid,setgameuserid] = useState(profile_gameuserid);
    const [gameusername,setgameusername] = useState(profile_gameusername);
    const [gameusermemo,setgameusermemo] = useState(memo);


    const [user] = useAuthState(auth);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      menuhandleClose();
    };

    //データ更新===================================
  const handleUpdate = async(event) => {
    event.preventDefault();
    const PlayerGames_docRef = doc(db, "PlayerGames", document_id);


    const game_data = {
      'gamename':gamename,
      'gameusername':gameusername,
      'gameuserid':gameuserid,
      'uuid':user.uid,
      'memo':gameusermemo
    }

    await updateDoc((PlayerGames_docRef),game_data);

    setOpen(false);
    menuhandleClose();
  };

  return (
    <React.Fragment>
        <div className='edit_button_regist' onClick={handleClickOpen}>
          <Tooltip className='edit_delete_button_tooltip' title={t('edit_game')} placement="top" sx={{ margin: '6px 0 0 0' }}>
          <EditIcon className='edit_icon'  />
          <span>{t('edit_game')}</span>

          {/* <EditIcon className='edit_icon' onClick={handleClickOpen} sx={{ padding: "3px 3px",margin: 0, }} /> */}

          </Tooltip>
        </div>
        <form onSubmit={handleUpdate}>
        <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
            event.preventDefault();
            handleClose();
            },
        }}
        >
        <DialogTitle className=''>{t('edit_game_title')}</DialogTitle>
        <DialogContent>
            <DialogContentText>
            {t('edit_game_title_text')}
            </DialogContentText>
            <img className='edit_delete_icon' alt='' src={gameimg}/>
            <TextField
            autoFocus
            disabled

            margin="dense"
            id="gamename"
            name="text"
            label={t('gametitle')}
            type="text"
            fullWidth
            variant="standard"
            value={gamename}
            onMouseDown={(event) => event.stopPropagation()}
            // onChange={(event) => setgamename(event.target.value)}
            />
            <TextField
            autoFocus
            required
            margin="dense"
            id="username"
            name="text"
            label={t('username')}
            type="text"
            fullWidth
            variant="standard"
            value={gameusername} 
            onChange={(event) => setgameusername(event.target.value)}
            onMouseDown={(event) => event.stopPropagation()}
            />
            <TextField
            autoFocus
            required
            margin="dense"
            id="userid"
            name="text"
            label="ID"
            type="text"
            fullWidth
            variant="standard"
            value={gameuserid} 
            onChange={(event) => setgameuserid(event.target.value)}
            onMouseDown={(event) => event.stopPropagation()}
            />
            <TextField
            autoFocus
            required
            margin="dense"
            id="Memo"
            name="text"
            label="Memo"
            type="text"
            fullWidth
            variant="standard"
            value={gameusermemo} 
            onChange={(event) => setgameusermemo(event.target.value)}
            onMouseDown={(event) => event.stopPropagation()}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type='submit'>{t('save')}</Button>
        </DialogActions>
        </Dialog>
        </form>
    </React.Fragment>
  )
}

export default Editbutton;