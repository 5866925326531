import React from 'react'
import { Button, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import {  collection, deleteDoc, getDocs, query, where } from 'firebase/firestore';

//icon
import DeleteIcon from '@mui/icons-material/Delete';
import { t } from 'i18next';

//登録ゲーム編集

function Deletegamebutton({ index,uuid,profile_gamename, profile_gameusername, profile_gameuserid,gameimg,menuhandleClose }) {
    const [open, setOpen] = React.useState(false);
    const [user] = useAuthState(auth);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      menuhandleClose();
    };

    const handleDialogClick = (event) => {
      event.stopPropagation(); // Dialog内のクリックイベントを止める
    };

    //データ更新===================================
  //削除ボタン＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    //削除
    const gamedelete = async (index,uuid,gamename, gameusername, gameuserid) => {
      const q = query(collection(db,'PlayerGames')
      ,where('gamename', '==', gamename)
      ,where('gameusername', '==', gameusername)
      ,where('gameuserid', '==', gameuserid)
      ,where('uuid', '==', uuid));

      //監視
      // const unsubscribe = onSnapshot(q, (querySnapshot) => {
      //   querySnapshot.docChanges().forEach(async (doc) => {
      //     await deleteDoc(doc.ref);
      //   });
      // });

      try {
        const querySnapshot = await getDocs(q);
        const deletePromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));
        await Promise.all(deletePromises);
      } catch (error) {
        console.error('Error deleting matching documents: ', error);
      }
      menuhandleClose();
    };

  return (
    <React.Fragment>
    <div>
      <Tooltip className='edit_delete_button_tooltip' title={t('delete')} placement="top" onClick={handleClickOpen}>
        <DeleteIcon className='profile_game_delete_button' />
        <span>{t('delete')}</span>
      </Tooltip>
    </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClick={handleDialogClick}
      >
        <DialogTitle id="alert-dialog-title">
          {t('delete_game_title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img className='edit_delete_icon' src={gameimg}/>
            <TextField
          disabled

            margin="dense"
            id="gamename"
            name="text"
            label={t('gametitle')}
            type="text"
            fullWidth
            variant="standard"
            defaultValue={profile_gamename}
            onMouseDown={(event) => event.stopPropagation()}
            // onChange={(event) => setgamename(event.target.value)}
            />
            <TextField
          disabled

            margin="dense"
            id="username"
            name="text"
            label={t('username')}
            type="text"
            fullWidth
            variant="standard"
            defaultValue={profile_gameusername}
            onMouseDown={(event) => event.stopPropagation()}
            />
            <TextField
          disabled

            margin="dense"
            id="userid"
            name="text"
            label="ID"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={profile_gameuserid}
            onMouseDown={(event) => event.stopPropagation()}
            />
            <p> </p>
            {t('delete_game_confirm')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('close')}</Button>
          <Button onClick={() => gamedelete(index,user.uid, profile_gamename, profile_gameusername, profile_gameuserid)}>{t('delete')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default Deletegamebutton;