import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { CircularProgress, IconButton, InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { collection, doc, endAt, getDoc, getDocs, limit, orderBy, query, startAt, where } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { useState } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import '../Newcss/NewGameSearch.css';
import WestIcon from '@mui/icons-material/West';
import NewSidebar from './NewSidebar';



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({userImageUrl}) {
    const { pk } = useParams();
    console.log(pk)

  const [value, setValue] = useState(0);
  const [gameinfo, setgameinfo] = useState([]);
  const [appusername, setappusername] = useState([]);

  const [UserInfo,setUserInfo] = useState([]);
  const [userImages, setUserImages] = useState([]);
  const [IDuser, setIDuser] = useState([]);
  const [IDuserImages, setIDUserImages] = useState([]);

  const [loading, setloading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('gamesearch_tab',newValue)
  };

  useEffect(() => {
    //タブ１
    const gamesearch_tab = localStorage.getItem('gamesearch_tab')
    if(gamesearch_tab !== null){
      setValue(Number(gamesearch_tab))
    }

    const gamenameuser_json = localStorage.getItem(`gamenameuser_${pk}`);
    if(gamenameuser_json !== null){
      const gamenameuser = JSON.parse(gamenameuser_json)
      setUserInfo(gamenameuser)
    }
    const gamenameuser_img_json = localStorage.getItem(`gamenameuser_img_${pk}`);
    if(gamenameuser_img_json !== null){
      const gamenameuser_img = JSON.parse(gamenameuser_img_json)
      setUserImages(gamenameuser_img)
    }

    const gameiduser_json = localStorage.getItem(`gameiduser_${pk}`);
    if(gameiduser_json !== null){
      const gameiduser = JSON.parse(gameiduser_json)
      setIDuser(gameiduser)
    }
    const gameiduser_img_json = localStorage.getItem(`gameiduser_img_${pk}`);
    if(gameiduser_img_json !== null){
      const gameiduser_img = JSON.parse(gameiduser_img_json)
      setIDUserImages(gameiduser_img)
    }
  },[])

  useEffect(() => {
    const fetchData = async () => {
      //プロフィール
      const userinfoQuery = query(collection(db, 'PlayerGames'), where('gameid', '==', pk), orderBy("createdAt"),limit(1));
      const userinfoQuerySnapshot = await getDocs(userinfoQuery);
      setgameinfo(userinfoQuerySnapshot.docs.map(doc => ({ ...doc.data() })));
      console.log(userinfoQuerySnapshot.docs.map(doc => ({ ...doc.data() })));
    }
    fetchData();
  },[])



    //=======================ゲームユーザー検索======================
    const SearchUser = async () => {
      try {
        const userQuery = query(
          collection(db, 'PlayerGames'),
          where('gameid', '==', pk), 
          orderBy('gameusername'),startAt(appusername),endAt(appusername + '\uf8ff'),limit(50)
        );  
        const userSnapshot = await getDocs(userQuery);
        setUserInfo(userSnapshot.docs.map(doc => ({ ...doc.data() })));
        localStorage.setItem(`gamenameuser_${pk}`,JSON.stringify(userSnapshot.docs.map(doc => ({ ...doc.data() }))))
  
        const userlist = userSnapshot.docs.map(doc => Object.values(doc.data().uuid).join(''));
  
        const images = await Promise.all(
          userlist.map(async (uuid) => {
            try {
              const gameUrl = await getDownloadURL(ref(storage, '/user_images/' + uuid + '.jpg'));
              return gameUrl;
            } catch (error) {
              const gameUrl = await getDownloadURL(ref(storage, '/user_images/' + 'noimage' + '.jpg'));
              return gameUrl;
            }
          })
        );
  
        setUserImages(images);
        localStorage.setItem(`gamenameuser_img_${pk}`,JSON.stringify(images));
  
        
        //ゲーム画像
      //   const gameUrl = await getDownloadURL(ref(storage, '/ゲームイメージ/' + SearcGame + '.jpeg'));
      //   setGameImages(gameUrl);
  
      } catch (error) {
        console.error('ユーザーデータの取得エラー:', error);
      }
    };
    //================================ゲームID検索===================================================

const SearchIDUser = async () => {
  try {
    const userQuery = query(
      collection(db, 'PlayerGames'),
      where('gameid', '==', pk), 
      orderBy('gameuserid'),
      startAt(appusername),
      endAt(appusername + '\uf8ff'),
      limit(30)
    );  
    const userSnapshot = await getDocs(userQuery);
    setIDuser(userSnapshot.docs.map(doc => ({ ...doc.data() })));
    localStorage.setItem(`gameiduser_${pk}`,JSON.stringify(userSnapshot.docs.map(doc => ({ ...doc.data() }))))

    const userlist = userSnapshot.docs.map(doc => Object.values(doc.data().uuid).join(''));

    const images = await Promise.all(
      userlist.map(async (uuid) => {
        try {
          const gameUrl = await getDownloadURL(ref(storage, '/user_images/' + uuid + '.jpg'));
          return gameUrl;
        } catch (error) {
          const gameUrl = await getDownloadURL(ref(storage, '/user_images/' + 'noimage' + '.jpg'));
          return gameUrl;
        }
      })
    );

    setIDUserImages(images);
    localStorage.setItem(`gameiduser_img_${pk}`,JSON.stringify(images));


    
    //ゲーム画像
    // const gameUrl = await getDownloadURL(ref(storage, '/ゲームイメージ/' + SearcGame + '.jpeg'));
    // setGameImages(gameUrl);

  } catch (error) {
    console.error('ユーザーデータの取得エラー:', error);
  }
};
const search_user = async(event) => {
  // event.preventDefault();
  setloading(true)
  await SearchUser();
  await SearchIDUser();
  setloading(false)
  };

  const handleKeyDown_gameuser = async(event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // デフォルトのEnterキー動作を防止する
      search_user();
    }
  };

  const UserInfo_length = () => {
    if (UserInfo.length === 0){
      return(
        <div className='nogatti'>
        <h3>合致するユーザーがいません</h3>
        </div>
      )
    }
  }

  const IDuser_length = () => {
    if (IDuser.length === 0){
      return(
        <div className='nogatti'>
        <h3>合致するユーザーがいません</h3>
        </div>
      )
    }
  }
    //================================================================================
  return (
    <div  className='app'> 
    <div className='app_sidebar'>
    <NewSidebar
      userImageUrl={userImageUrl}
    />
    </div>
    <div className='Search'>
    <div className='search_body'>
        <Link to='/Search/' className='gamesearch_return_button'>
          <WestIcon className='game_search_icon'/>
        </Link>


              <Box
      sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: '25%',marginLeft: '25%' }}
      >
        
        <Paper
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400,marginTop: "25px"}}
      >
        
        {gameinfo.map((game,index) => (
      <div className='gamesearch_result' key={game.gamename+index}>
          <img className='gamesearch_img' src={game.gameimgurl}/>
      </div>
              ))}
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="ユーザー名 or ID"
          inputProps={{ 'aria-label': 'search google maps' }}
          value={appusername} 
          onChange={(event) => setappusername(event.target.value)}
          onKeyDown={handleKeyDown_gameuser}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={search_user}>
        {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search"> */}

          <SearchIcon />
        </IconButton>
      </Paper>
      
    </Box>
        
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
          <Tab label={`アカウント ${UserInfo.length+'件'}`} {...a11yProps(0)} />
          <Tab label={`ID ${IDuser.length+'件'}`} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      {loading === true ? (
      <CircularProgress />
    ) : (
      <>
      <UserInfo_length/>
      {UserInfo.map((user,index) => (
            <div key={user.Username} className='search_result'>
              <Link to={`/Profile/${user.uuid}`} >
              <img className='search_user_img' src={userImages[index]} />
              </Link>
              <div className='search_gameuser'>
                <h3 className='search_username'>{user.gameusername}</h3>
                <p className='search_userid'>@{user.gameuserid}</p>
                </div>
          </div>
          ))}
      </>
    )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      {loading === true ? (
      <CircularProgress />
    ) : (
      <>
        <IDuser_length/>
      {IDuser.map((user,index) => (
          <div key={user.Username} className='search_result'>
            <Link to={`/Profile/${user.uuid}`} >
            <img className='search_user_img' src={IDuserImages[index]} />
            </Link>
            <div className='search_gameuser'>
            <h3 className='search_username'>{user.gameusername}</h3>
            <p className='search_userid'>@{user.gameuserid}</p>
            </div>
          </div>
        ))}
        </>
    )}
      </CustomTabPanel>
    </Box>
    </div>
    </div>
    </div>
  );
}