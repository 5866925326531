import React from 'react'
import rogo from '../IDnest.jpeg'

import { Link } from 'react-router-dom'
import '../Newcss/Landingpage.css';

import land1 from '../../img/land_1.png';
import land2 from '../../img/land_2.png';
import land3 from '../../img/land_3.png';
import { useTranslation } from 'react-i18next';




function LandingpageEng() {
  const { t,i18n } = useTranslation();

  return (
    <>
<div id="header">
    <div className='login_header_buttons'>
        <Link to='/'><img className='profile_rogo' src={rogo}/></Link>
        <h4 className='idnest'>IDNect</h4>
        <Link to='/login' className='nouser_button'>signup or login</Link>
    </div>
</div>
{/* 700 and above */}
<div className='Landing'>
  <div>
      <div className='Landing_text'>
      <Link to='/en' >English</Link>
      <Link to='/' >日本語</Link>
          <h2>Manage, Share, and Search Game IDs Easily</h2>
      </div>
      <div className='land'>
        <div className='landtext'>
          <h3>Manage Your Games</h3>
          <p>You can register over 10,000 games. By registering, you can be assured that you have your IDs when needed, even if data is lost.</p>
        </div>
        <img className='land1_img' src={land1}/>
      </div>
      <div className='land'>
        <img className='' src={land2}/>
        <div className='landtext'>
          <h3>Share Game IDs</h3>
          <p>You can check the registered games and IDs of your friends. Shorten the time it takes to ask for or give IDs. You can also share your own URL.</p>
        </div>
      </div>
      <div className='land land3'>
        <div className='landtext'>
          <h3>Find People by Game ID</h3>
          <p>You can search by game. You can find people just by their names or IDs in the game.</p>
          <p>(To contact them, they need to have their social media registered.)</p>
        </div>
        <img className='' src={land3}/>
      </div>
  </div>
</div>
<div class="land_footer">
      <div className='land_language'>
        <a></a>
        <div>
          <a className='language_select' onClick={() => {i18n.changeLanguage("en"); localStorage.setItem('language', 'en');}}>English</a>
          <a className='language_select' onClick={() => {i18n.changeLanguage("ja");localStorage.setItem('language', 'ja');}}>日本語</a>
        </div>
        <a></a>
      </div>
      <div>
        <ul class="land_footer-links">
          <div>
            <li>Contact Us</li>
            <li><Link to='/tos'>Terms of Service</Link></li>
            <li><Link to='/pp'>Privacy Policy</Link></li>
          </div>
        </ul>
      </div>
</div>
  </>
  )
}

export default LandingpageEng