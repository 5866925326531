import { initializeApp } from "firebase/app";
import {GoogleAuthProvider, signInWithEmailAndPassword, signOut} from 'firebase/auth';
import { getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyAgqsa6hKh--DZ9PyopolfZKAkcMu8qCzw",
  // authDomain: "https://idnect.com",
  authDomain: "fir-trial2-425ff.firebaseapp.com",

  databaseURL: "https://fir-trial2-425ff-default-rtdb.firebaseio.com",
  projectId: "fir-trial2-425ff",
  storageBucket: "fir-trial2-425ff.appspot.com",
  messagingSenderId: "196827584264",
  appId: "1:196827584264:web:a25faae1c34185b02b7ebd",
  measurementId: "G-1PG8VGEZB4"
};

// 初期化やら各種設定
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage();
const storageRef = ref(storage);
const provider = new GoogleAuthProvider();

//メールでの新規登録

//メールでのログイン
const SigninWithEmailAndPassword = async(email,password) => {
    const user = await signInWithEmailAndPassword(auth, email, password);
    localStorage.setItem('login',user.user.uid)
    alert('ログイン成功');
};
export {firebaseConfig,app,auth,provider,db,storageRef,storage,SigninWithEmailAndPassword};

export const firebase_Signout = async() => {
  await signOut();
  };

//ログアウト
export function SignOutButton(){
    return (
        <button className='Logout' onClick={() => auth.signOut()}>
            <p>サインアウト</p>
        </button>
    )
  }