import React, { useState } from 'react'
import { SigninWithEmailAndPassword } from '../firebase';
import { useNavigate } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAdditionalUserInfo } from 'firebase/auth';
import { TextField } from '@mui/material';
import { t } from 'i18next';

function Mail_Login() {
    const [email2,setEmail] = useState('');
    const [password2,setPassword] = useState('');

    const [mail_error,setmail_error] = useState("");


    const login = async(event) => {
        event.preventDefault();
        try{
          const userCredential  = await SigninWithEmailAndPassword(email2,password2);
          console.log(userCredential)

          // if(userCredential){
          //   // const user = userCredential.user;
          //   console.log(userCredential)
          //   const isNewUser = getAdditionalUserInfo(userCredential)
          //   // localStorage.setItem('login',user.uid)

          // }

          
        }catch(error){
          console.log(error)
          switch (error.code) {
            case 'auth/invalid-email':
              setmail_error('auth/invalid-email');
              break;
            case 'auth/user-disabled':
              setmail_error('auth/user-disabled');
              break;
            case 'auth/user-not-found':
              setmail_error('auth/user-not-found');
              break;
            case 'auth/wrong-password':
              setmail_error('auth/wrong-password');
              break;
            case 'auth/email-already-in-use':
              setmail_error('auth/email-already-in-use');
              break;
            case 'auth/operation-not-allowed':
              setmail_error('auth/operation-not-allowed');
              break;
            case 'auth/weak-password':
              setmail_error('auth/weak-password');
              break;
            case 'auth/network-request-failed':
              setmail_error('auth/network-request-failed');
              break;
            default:
              setmail_error('default');
          }}
        }

  return (
    <div>
      <h2>{t('Login')}</h2>
      {/* {mail_error} */}
      {t(mail_error)}
        {/* <form onSubmit={login}> */}
        <div>
          <TextField sx={{width: '45vh'}} type='text'  variant="outlined" value={email2} placeholder={t('mailaddress')} onChange={(event) => setEmail(event.target.value)}/>
        </div>
        <div>
          <TextField sx={{width: '45vh'}} type='password'  variant="outlined" value={password2} placeholder={t('password')} onChange={(event) => setPassword(event.target.value)}/>
        </div>
            <p className='loginbutton' onClick={login}>{t('Login')}</p>
        {/* </form> */}
    </div>
  )
};

export default Mail_Login;