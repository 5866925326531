import React, { useState } from 'react';

const ImageUploader = () => {
  const [text, setText] = useState('');
  const [text1, setText1] = useState('');


  const handleChange = (event) => {
    setText(event.target.value);
    setText1(event.target.value.toLowerCase())
  };

  return (
    <div>
      <input
        type="text"
        value={text}
        onChange={handleChange}
        placeholder="文字を入力してください"
      />
      {text1}
      <p>変換された文字: {text}</p>
    </div>
  );
}

export default ImageUploader;