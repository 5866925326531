import React, { memo, useMemo, useState } from 'react'
import { SignInButton, SignOutButton } from '../Home';
import Google_login_button from './google_login_button'
import Mail_Login from './Mail_Login';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import './login.css';
import rogo from '../img/IDnest.jpeg'

import { BrowserRouter as Router, Navigate, Route, RouteProps, Switch, Link } from 'react-router-dom';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { createUserWithEmailAndPassword, getAdditionalUserInfo, sendEmailVerification, signOut } from 'firebase/auth';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';
import Remail from './Remail';
import Login_Reset_password from './Login_Reset_password';
import { useTranslation } from 'react-i18next';
import { SignInWithApple } from './SignInWithApple';



function Login() {
  const [open, setOpen] = React.useState(false);
  const [email, setemail] = React.useState();
  const [password, setpassword] = React.useState();
  const [username, setUsername] = React.useState();
  const [message, setmessage] = React.useState();
  const [user] = useAuthState(auth);
  const [mail_user_create_error,setmail_user_create_error] = useState()
  localStorage.setItem('page','login');

  const { t,i18n } = useTranslation();



  const Navigate_profile = () => {
    if (user && user.emailVerified) {
      const pk = user.uid;
      localStorage.setItem('login',user.uid)
      return <Navigate to={`/Profile/${pk}`} />;
    }
    return null; // 条件に一致しない場合は null を返す
  };


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const account_regist = async(event) => {
    event.preventDefault();
    try{
      const user = await SignupWithEmailAndPassword(email,password,username);

      // console.log(email+','+password)
      if(user){
        setOpen(false);
      }
    } catch (error) {
      // alert('エラーが発生しました');
      console.log('firebaseエラー: ', error.code);
  
      switch (error.code) {
        case 'auth/invalid-email':
          setmail_user_create_error('無効なメールアドレス形式です。正しいメールアドレスを入力してください。');
          break;
        case 'auth/user-disabled':
          setmail_user_create_error('このアカウントは無効化されています。サポートにお問い合わせください。');
          break;
        case 'auth/user-not-found':
          setmail_user_create_error('このメールアドレスに対応するアカウントが見つかりませんでした。再度ご確認ください。');
          break;
        case 'auth/wrong-password':
          setmail_user_create_error('パスワードが正しくありません。もう一度お試しください。');
          break;
        case 'auth/email-already-in-use':
          setmail_user_create_error('このメールアドレスは既に使用されています。別のメールアドレスを試してください。');
          break;
        case 'auth/operation-not-allowed':
          setmail_user_create_error('この操作は現在許可されていません。管理者にお問い合わせください。');
          break;
        case 'auth/weak-password':
          setmail_user_create_error('パスワードが弱すぎます。より強力なパスワードを設定してください。');
          break;
        case 'auth/network-request-failed':
          setmail_user_create_error('ネットワーク接続に失敗しました。インターネット接続を確認して再試行してください。');
          break;
        default:
          setmail_user_create_error('予期しないエラーが発生しました。', error.message);
      }}}

      const SignupWithEmailAndPassword = async(email,password,username) => {
        // const [error,seterror] = useState()

        if (email && password && username) {
        try {
          const user = await createUserWithEmailAndPassword(auth, email, password)
          const isNewUser = getAdditionalUserInfo(user)
          // console.log(isNewUser)
          // console.log(user)
          // console.log(user.user.uid);
          const uid = user.user.uid
          await sendEmailVerification(user.user);
      
          if(user && isNewUser){
            //UserInfo
            const UserInfoRef = doc(db, 'UserInfo',uid);
            setDoc(UserInfoRef,{
              Username: username ,
              Username_lower:username.toLowerCase(),
              UserID: uid ,
              UserID_lower:uid.toLowerCase(),
              introduction : '' ,
              uuid: uid ,
              createdAt: serverTimestamp()
            });
            //SNS
            const SNSRef = doc(db, 'SNS',uid);
            setDoc(SNSRef,{
              'Discord':'',
              'Tiktok':'',
              'Twitch':'',
              'X':'',
              'Youtube':'',
              "uuid": uid ,
            });
            //Friends
            const FriendsRef = doc(db, 'Friends',uid);
            setDoc(FriendsRef,{
              follower:[],
              following: [],
              friend: [],
              uuid: uid,
            });
          }
          const sendmailmassage = email+'に認証メールを送信しました。メール認証完了後、ログインをすることが可能になります。'
          setmessage(sendmailmassage)
      
          // alert('確認メールを送信しました');

          return user;
        } catch (error) {
          // alert('エラーが発生しました');
          console.log('firebaseエラー');
          console.log(error);
          switch (error.code) {
            case 'auth/invalid-email':
              setmail_user_create_error('auth/invalid-email');
              break;
            case 'auth/user-disabled':
              setmail_user_create_error('auth/user-disabled');
              break;
            case 'auth/user-not-found':
              setmail_user_create_error('auth/user-not-found');
              break;
            case 'auth/wrong-password':
              setmail_user_create_error('auth/wrong-password');
              break;
            case 'auth/email-already-in-use':
              setmail_user_create_error('auth/email-already-in-use');
              break;
            case 'auth/operation-not-allowed':
              setmail_user_create_error('auth/operation-not-allowed');
              break;
            case 'auth/weak-password':
              setmail_user_create_error('auth/weak-password');
              break;
            case 'auth/network-request-failed':
              setmail_user_create_error('auth/network-request-failed');
              break;
            default:
              setmail_user_create_error('default');
          }
          return;
        }}else{
          setmail_user_create_error('default')
        }
        try{
          await signOut(auth);
        }catch(error){
          console.log('ログアウトエラー')
        }

      };
    



  return (
    <div>
          <div id="header">
            <div className='login_header_buttons'>
            <Link to='/'><img className='profile_rogo' src={rogo}/></Link>
            <h4 className='idnest'>IDNect</h4>
              <a className='margin'></a>
            </div>
          </div>
    <div className='login_body'>
      <Navigate_profile />
      <div className='login'>

        <div className='login_form'>
        <a>{message}</a>


          {/* <SignInButton /> */}

          <Mail_Login />
          <p className='mataha'>{t('or')}</p>
          <Google_login_button />


          {/* =============新規登録 */}
      <div>
    <React.Fragment>
        <div className='regist_newuser'>
        {/* <Button variant="outlined" className='regist_button' onClick={handleClickOpen}>
        新規登録
        </Button> */}
        <p className='regist_button' onClick={handleClickOpen}>{t('create_account')}</p>
        </div>
        <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            // const email = formJson.email;
            // console.log(email);
            handleClose();
            },
        }}
        >
        <DialogTitle className='popname'>{t('create_account')}</DialogTitle>
        <DialogContent>
            <DialogContentText>
            {t('required-fields')}
            </DialogContentText>

            <div className='error_message'>{t(mail_user_create_error)}</div>
            <TextField
            autoFocus
            required
            margin="dense"
            id="email"
            name="text"
            label={t('mailaddress')}
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(event) => setemail(event.target.value)}
            />
            <TextField
            autoFocus
            required
            margin="dense"
            id="password"
            name="text"
            label={t('password')}
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(event) => setpassword(event.target.value)}
            />
            <TextField
            autoFocus
            required
            margin="dense"
            id="username"
            name="text"
            label={t('username')}
            type="text"
            fullWidth
            variant="standard"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            />

        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type='submit' onClick={account_regist}>{t('regist')}</Button>
        </DialogActions>
        <DialogContentText className='explanation'>
            {/* 登録後メールアドレスへ認証メールが送られます。
            認証メールを確認後、ログインを行ってください。 */}
            </DialogContentText>
        </Dialog>
    </React.Fragment>

    <Login_Reset_password/>
    <Remail/>
    {/* <SignInWithApple/> */}
    {/* <div>
      <button onClick={SignInWithApple}>Sign in with Apple</button>
    </div> */}
    </div>
    </div>
      </div>
  </div>
  <div class="footer">
          <div className='language'>
            <a></a>
            <div>
              <a className='language_select' onClick={() => {i18n.changeLanguage("en"); localStorage.setItem('language', 'en');}}>English</a>
              <a className='language_select' onClick={() => {i18n.changeLanguage("ja");localStorage.setItem('language', 'ja');}}>日本語</a>
            </div>
            <a></a>
          </div>
          <div>
            <ul class="footer-links">
              <div>
                <li>お問い合わせ</li>
                <li><Link to='/tos'>利用規約</Link></li>
                <li><Link to='/pp'>プライバシーポリシー</Link></li>
              </div>
            </ul>
          </div>
    </div>
    <footer class="mobile_footer">
        <div class="footer-container">
          <a className='' onClick={() => {i18n.changeLanguage("en"); localStorage.setItem('language', 'en');}}>English</a>
          <a className='' onClick={() => {i18n.changeLanguage("ja");localStorage.setItem('language', 'ja');}}>日本語</a>
            <ul class="footer-links">
                <li>お問い合わせ</li>
                <li><Link to='/tos'>利用規約</Link></li>
                <li><Link to='/pp'>プライバシーポリシー</Link></li>
            </ul>
        </div>
    </footer>
  </div>
  )
};

export default Login;
