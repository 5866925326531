import React from 'react'
import rogo from '../IDnest.jpeg'
import '../Newcss/Riyoukiyaku.css';
import { Link } from 'react-router-dom';


function Privacypolicy() {
  return (
    <div>
    <div id="header">
    <div className='login_header_buttons'>
    <Link to='/'><img className='profile_rogo' src={rogo}/></Link>
    <h4 className='idnest'>IDNect</h4>
    <a className='margin'></a>
    </div>
</div>
    <div className='riyoukiyaku'>          

        <div className='riyoukiyaku_text'>

        <h2>プライバシーポリシー</h2>
        IDNectをご利用いただきありがとうございます。本プライバシーポリシーは、当アプリケーション（以下、「当アプリ」）がユーザーから収集する情報、情報の利用方法、および情報の共有について説明するものです。当アプリを利用することで、本ポリシーに同意されたものとみなします。
        <h4>1.収集する情報</h4>
        1.1 直接提供される情報
        氏名、メールアドレス、電話番号、住所などの個人情報
        ユーザーがアカウント作成時に提供するその他の詳細
        1.2 自動収集情報
        デバイス情報（デバイスの種類、識別子、オペレーティングシステムのバージョン、設定等）
        ログ情報（IPアドレス、ブラウザの種類と設定、言語設定、接続日時等）
        位置情報（GPS情報、Wi-Fiアクセスポイントや基地局の情報等）
        使用状況データ（クリック、ページアクセス、滞在時間等）
        1.3 第三者からの情報
        ソーシャルメディアやその他の第三者サービスを通じて提供される情報
        <h4>2.情報の利用方法</h4>
        当アプリは収集した情報を以下の目的で利用します：
        サービスの提供、運営および改善
        カスタマーサポートの提供
        ユーザーエクスペリエンスのパーソナライゼーション
        マーケティングおよびプロモーション活動
        データ分析および研究
        <h4>3.情報の共有</h4>
        当アプリは、収集した情報を以下の第三者と共有する場合があります：
        3.1 サービスプロバイダー
        サービスの運営や改善を目的として、協力会社やパートナー企業に情報を提供します。
        3.2 ビジネスパートナー
        マーケティングや広告のために情報を共有します。
        3.3 法的要求
        法律および規制の要件を満たすために、政府機関や法執行機関に情報を提供する場合があります。
        3.4 その他
        合併、買収、資産売却、破産、その他の事業再編の際に情報を共有することがあります。
        <h4>4.データの安全性</h4>
        当アプリは、収集した情報を保護するための技術的および組織的な措置を講じていますが、完全な安全性を保証することはできません。情報の提供は自己責任で行ってください。
        <h4>5.プライバシーポリシーの更新</h4>
        当アプリは、必要に応じて本プライバシーポリシーを変更することがあります。変更後のポリシーは、当アプリ上に掲載された時点で効力を発生します。定期的にこのページを確認し、最新の情報をご確認ください。
        <h4>6.お問い合わせ</h4>
        プライバシーポリシーについてご不明点がある場合は、以下の連絡先までご連絡ください：
        Eメール：kosukesh34@gmail.com
        最終更新日: 2024年6月5日
        このプライバシーポリシーは、IDNectに適用されるものです。しかし、ユーザーのプライバシーの保護には万全を期すべきと考えており、常に改善に努めていきます。

            </div>
        </div>

    </div>
  )
}

export default Privacypolicy
