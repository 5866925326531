import React, { useEffect, useState } from 'react'
import {  useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { deleteUser, sendPasswordResetEmail } from "firebase/auth";
//dialog
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { auth, db, storage } from '../../firebase';

import '../Newcss/NewSetting.css';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyIcon from '@mui/icons-material/Key';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import TranslateIcon from '@mui/icons-material/Translate';
import { useTranslation } from 'react-i18next';
import NewSidebar from './NewSidebar';
import { arrayRemove, collection, deleteDoc, doc, getDocs, limit, query, updateDoc, where } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { TextField } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Helmet } from 'react-helmet-async';


function NewSetting({
  userImageUrl,
  friend_user,
  follow_user,
  follower_user,
  UserInfo}) {

const [user] = useAuthState(auth);
const localuuid = localStorage.getItem('login')
localStorage.setItem('page','setting');
//言語設定
const { t,i18n } = useTranslation();

// const auth = getAuth();
const navigate = useNavigate();
//dialog(再設定)==================
const [open, setOpen] = React.useState(false);



const handleClose = () => {
  setOpen(false);
};
//dialog(アカウント削除)
const [open_delete, setOpen_delete] = React.useState(false);

const handleClickOpen_delete = () => {
  setOpen_delete(true);
};

const handleClose_delete = () => {
  setOpen_delete(false);
};

if(! localuuid){
  const remove_app = () => {
    navigate('/');
  }
  remove_app()
}
//言語設定
const [open_language, setOpen_language] = React.useState(false);

const handleClickOpen_language = () => {
  setOpen_language(true);
};

const handleClose_language = () => {
  setOpen_language(false);
};
//ID変更＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
const [open_change_id, setOpen_change_id] = React.useState(false);
const [check_userid,setcheck_userid] = useState();
const [sameuser,set_sameuser] = useState();



const handleClickOpen_change_id = () => {
  setuserID(UserInfo[0].UserID); // 保存しない場合、元のUserIDに戻す

  setOpen_change_id(true);
};

const handleClose_change_id = () => {
  setOpen_change_id(false);

};
const [userID, setuserID] = useState();
const [error, setError] = useState('');
const hasError = error ? true : false;

useEffect(() => {
  try{
    setuserID(UserInfo[0].UserID)
    setcheck_userid(UserInfo[0].UserID)
  }catch(error){
    console.log(error)
  }

},[UserInfo])

const handleChange_userID = (e) => {
  const value = e.target.value;
  setuserID(value);

  if (value.length <= 2) {
    setError('3文字以上入力してください');
  } else if (!/^[a-zA-Z0-9_]*$/.test(value)) {
    setError('半角英数字 _ のみ使用できます');
  } else {
    setError('');
  }
};

const setting_changeID = async(event) => {
  event.preventDefault();
  if(userID !== check_userid){

    const check_id_q = query(collection(db, "UserInfo"), where("UserID", "==", userID), limit(1));
    const querySnapshot = await getDocs(check_id_q);
    
    for (const doc of querySnapshot.docs) {
      if (doc.exists()) {
        set_sameuser('このIDは使用されています');
        return;
      }
    }
  }else{
    set_sameuser('');
  }





  if (localuuid){
    const UserInfo_docRef = doc(db, "UserInfo", localuuid);

    const user_data = {
      'UserID':userID,
      "UserID_lower":userID.toLowerCase(),

    }

    await updateDoc((UserInfo_docRef),user_data);


    handleClose_change_id()
  }}

//========================================

//パスワード再設定
const reset_password_send_mail = async(event) => {

  event.preventDefault();
  sendPasswordResetEmail(auth, user.email)
  .then(() => {
    setOpen(true)
      // Password reset email sent!
      // ..
  })
  .catch((error) => {
      console.log(error.message)
  });
}
//＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
//アカウントを削除＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
const delete_user = async(event) => {
  event.preventDefault();
  //フォローしている人のフォロワーから削除＝＝＝＝＝＝＝＝＝＝＝＝
  try{
  for (const item of follow_user) {
      const followRef = doc(db, "Friends", item);
      await updateDoc(followRef, {
          follower: arrayRemove(localuuid),
      });
  }}catch(error){
  }
  //フォロワーのフォロー削除
  try{
    for (const item of follower_user) {
      const followerRef = doc(db, "Friends", item);
      await updateDoc(followerRef, {
        following: arrayRemove(localuuid),
      });
  }
  }catch(error){
    console.log(error)
  }
  //フレンドのフレンドから削除
  try{
    for (const item of friend_user) {
      const friendRef = doc(db, "Friends", item);
      await updateDoc(friendRef, {
        friend: arrayRemove(localuuid),
      });
  }
  }catch(error){
    console.log(error)
  }
  //自分のUserInfo削除===================================
  try{
    await deleteDoc(doc(db, "UserInfo", localuuid));
  }catch(error){
    console.log(error)
  }
  //SNSを削除=======================
  try{
    await deleteDoc(doc(db, "SNS", localuuid));
  }catch(error){
    console.log(error)
  }
  //PlayerGamse===========================
  try {
    const q = query(collection(db,'PlayerGames'),where('uuid', '==', localuuid));
    const querySnapshot = await getDocs(q);
    const deletePromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
    console.log('delete all data');
  } catch (error) {
    console.error('Error deleting matching documents: ', error);
  }
  localStorage.clear(); // ローカルストレージを削除
  auth.signOut(); // サインアウト

  const storageRef = ref(storage, 'user_images/' + localuuid + '.jpg');

  deleteObject(storageRef).then(() => {
    console.log('delete img')
    // File deleted successfully
  }).catch((error) => {
    // Uh-oh, an error occurred!
    console.log('not delete img')
  });



  deleteUser(user)
  .then(() => {
    console.log("User deleted successfully.");
  })
  .catch((error) => {
    console.error("An error occurred while deleting the user:", error);
  });
}

//＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝


  return (
    <div  className='app'> 
      <Helmet>
        <title>IDNect-Setting</title>
      </Helmet>
    <div className='app_sidebar'>
    <NewSidebar
      userImageUrl={userImageUrl}
    />
    </div>
    <div className='Setting'>
      <style>
      {`
        .settingside {
          font-weight: bold;
          background-color: rgb(231, 231, 231);
        }
      `}
      </style>
      <h3 className='pagetitle'>{t("setting")}</h3>
        <div className='setting_body'>
          <p className='Logout newprofile_button' onClick={() => { 
            localStorage.clear(); // ローカルストレージを削除
            auth.signOut(); // サインアウト
        }}><span className='newsetting_icon'><ExitToAppIcon/></span>{t("sign_out")}</p>
        {/* ========================言語設定====================================== */}
        
      <React.Fragment>
        <p className=' newprofile_button' onClick={handleClickOpen_language}><span className='newsetting_icon'><TranslateIcon/></span>{t("language_settings")}</p>
                <Dialog
          open={open_language}
          onClose={handleClose_language}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {t("language_settings")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
          <p className='language_button' onClick={() => {i18n.changeLanguage("en"); localStorage.setItem('language', 'en');}}>English</p>
          <p className='language_button' onClick={() => {i18n.changeLanguage("ja");localStorage.setItem('language', 'ja');}}>日本語</p>

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose_language}>{t("close")}</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
        {/* ============================================================== */}


      <React.Fragment>
        <p className='newprofile_button' onClick={reset_password_send_mail}><span className='newsetting_icon'><KeyIcon/></span>{t("resetting_password")}</p>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"パスワードの再設定"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              { user ?(
                <>
                <p>{user.email}</p>
                  <p>{t('send_mail')}</p>
                </>
              ):(
                <>
                </>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("close")}</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
{/* ============================================================== */}
      <React.Fragment>
        <p className='newsetting_account_delete_button newprofile_button' onClick={handleClickOpen_delete}><span className='newsetting_icon delete_user'><PersonOffIcon/></span>{t("delete_account")}</p>
        <Dialog
          open={open_delete}
          onClose={handleClose_delete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {t("delete_account")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {t("delete_account_text")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose_delete}>{t("close")}</Button>
            <Button onClick={delete_user}>{t("delete")}</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      {/* ========================================================================= */}
      <React.Fragment>
        <p className=' newprofile_button' onClick={handleClickOpen_change_id}><span className='newsetting_icon'><ModeEditIcon/></span>{t("change_id")}</p>
                <Dialog
          open={open_change_id}
          onClose={handleClose_change_id}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {t("language_settings")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            <div className='change_id_content'>

              <TextField
                id="outlined-required"
                label="ID"
                type="text"
                value={userID}
                onChange={handleChange_userID}
                error={hasError}
                autoFocus
              />
              {error && <p style={{ color: 'red' }}>{error}</p>}
              <p>{sameuser}</p>
            </div>


            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose_change_id}>{t("close")}</Button>
            <Button onClick={setting_changeID} disabled={!!error}>
            {t("save")}
          </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
        </div>
    </div>
    </div>
  )
};

export default NewSetting;