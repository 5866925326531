import React from 'react'
import rogo from '../IDnest.jpeg'
import '../Newcss/Riyoukiyaku.css';
import { Link } from 'react-router-dom';


function Riyoukiyaku() {
  return (
    <div>
    <div id="header">
    <div className='login_header_buttons'>
    <Link to='/'><img className='profile_rogo' src={rogo}/></Link>
    <h4 className='idnest'>IDNect</h4>
    <a className='margin'></a>
    </div>
</div>
    <div className='riyoukiyaku'>          

        <div className='riyoukiyaku_text'>

        <h2>利用規約</h2>
            <h3>IDNectアプリ利用規約</h3>
            有効日: 2024年6月1日
            <h4>受け入れと同意</h4>
            IDNectアプリケーション（以下、「アプリ」と称します）をご利用いただく際には、以下の利用規約（以下、「規約」と称します）に同意する必要があります。規約は、IDNect（以下、「事業者」と称します）とユーザー（以下、「利用者」と称します）の間の法的な契約を構成します。アプリの利用を続けることで、利用者は規約に同意したものとみなされます。規約に同意できない場合、アプリの利用を中止してください。

            <h4>アカウント</h4>
            2.1 利用者は、アプリを利用するために、事業者が提供する登録プロセスを完了し、アカウントを作成する必要があります。アカウント情報は正確かつ最新のものでなければなりません。
            2.2 利用者は、アカウント情報の機密性を維持し、第三者と共有しない責任を負います。アカウントが不正に利用された場合、利用者は直ちに事業者に通報しなければなりません。
            <h4>アプリの利用</h4>
            3.1 利用者は、アプリを利用する際に、全ての適用法令と規制に従わなければなりません。アプリの利用は、合法的な目的のみに制限されます。
            3.2 利用者は、アプリを以下の目的で利用してはなりません：
            不正行為、スパム、詐欺行為、または他の利用者への迷惑行為
            アプリのセキュリティや機能に対する不正アクセスまたは攻撃
            事業者の著作権、商標、プライバシー権などの権利の侵害
            <h4>プライバシー</h4>
            4.1 事業者は、利用者のプライバシーを尊重し、プライバシーポリシーに従って利用者の個人情報を取り扱います。プライバシーポリシーについては、事業者のウェブサイトで確認できます。
            サポートと連絡先
            5.1 利用者がアプリに関する質問、提案、または問題を報告したい場合、事業者のサポートチームにご連絡いただけます。連絡先情報は事業者ウェブサイトに掲載されています。
            <h4>変更と解除</h4>
            6.1 事業者は、規約を変更する権利を有します。変更内容は本ウェブサイトに掲示され、有効となる日付が明示されます。変更内容に同意できない場合、利用者はアプリの利用を中止するか、アカウントを削除できます。
            6.2 事業者は、違反行為や規約に違反した利用者に対して、通告なしにアクセス権の停止やアカウントの解除を行う権利を有します。
            <h4>免責事項</h4>
            7.1 アプリは明示または黙示を問わず、いかなる保証も行いません。事業者は、利用者がアプリを利用する際に、安全かつ効果的なサービス提供を目指し最大限の努力を行いますが、それに関して一切の保証を行いません。また、利用者はアプリを自己責任で利用するものとし、事業者は利用者の損害について一切の責任を負いません。なお、より安全で最適なサービスを提供するために、可能な限り利用者のデータを収集・分析します。
            <h4>その他の規定</h4>
            8.1 本規約に関する一切の紛争は、日本国の法律に従って解決されます。
            8.2 本規約のいずれかの規定が無効とされた場合、それ以外の規定は有効とし、継続して適用されます。
            8.3 規約に関するすべての紛争の裁判管轄は、福岡地方裁判所とします。ただし、特定の状況により変更が必要な場合があります。その際は、事前に利用者に連絡し、合意を得るよう努めます。
            <h4>お問い合わせ</h4>
            本利用規約に関するお問い合わせは、以下の連絡先にお願いいたします。
            kosukesh34@gmail.com
            有効日: 2024年6月1日

            </div>
        </div>

    </div>
  )
}

export default Riyoukiyaku