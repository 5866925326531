import React, { useState,useEffect,  memo } from 'react';
import { collection, endAt, getDocs, limit, orderBy, query, startAt } from "firebase/firestore"; 


import { Box, CircularProgress } from '@mui/material';
import { getDownloadURL, ref } from 'firebase/storage';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

// import { makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography } from '@mui/material';
import { auth, db, storage } from '../../firebase';

import '../Newcss/NewSearch.css';
import { useTranslation } from 'react-i18next';
import NewSidebar from './NewSidebar';
import { Helmet } from 'react-helmet-async';





//タブのためのもの========ゲーム============================
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


//タブ=================大本（アプリｏｒゲーム）=====================
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps_select(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


//==========================================================




function NewSearch({PlayerGames,gameImages,userImageUrl}) {

  const [user] = useAuthState(auth);
  //ゲーム選択css用

  localStorage.setItem('page','search');

//タブ


const navigate = useNavigate();
//言語設定
const { t } = useTranslation();

//ログイン中華どうか
const [localuuid,setlocaluuid] = useState(0);

useEffect(() => {
  const uuid = localStorage.getItem('login')
  setlocaluuid(uuid)
},[])


if(localuuid === null){
  const remove_app = () => {
    navigate('/');
    console.log(user)
  }
  remove_app()
}

  //アプリ内検索用
  const [appusername, setappusername] = useState([]);
  const [appuser, setappuser] = useState([]);
  const [appuserImages, setappUserImages] = useState([]);

  const [appiduser, setappiduser] = useState([]);
  const [appIDUserImages, setappIDUserImages] = useState([]);


  const [loading, setloading] = useState(false);


  // const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('tab_2',newValue)

  };

  useEffect(() => {
    //タブ１
    const tab_1 = localStorage.getItem('tab_1')
    if(tab_1 !== null){
    }
    //タブ2
    const tab_2 = localStorage.getItem('tab_2')
    if(tab_2 !== null){
    setValue(Number(tab_2))
    }
    //検索テキスト
    const search_text = localStorage.getItem('search_text')
    setappusername(search_text)
    //アプリ名前検索＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    const appuser_json = localStorage.getItem('appuser');
    if(appuser_json !== null){
      const appuser = JSON.parse(appuser_json)
      setappuser(appuser)
    }
    const appUserImages_json = localStorage.getItem('appUserImages');
    if(appUserImages_json !== null){
    const appUserImages = JSON.parse(appUserImages_json)
    setappUserImages(appUserImages)
   }
    //アプリID検索＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
    const appiduser_json = localStorage.getItem('appiduser');
    if(appiduser_json !== null){
    const appiduser = JSON.parse(appiduser_json)
    setappiduser(appiduser)
   }
    const appIDUserImages_json = localStorage.getItem('appIDUserImages');
    if(appIDUserImages_json !== null){
    const appIDUserImages = JSON.parse(appIDUserImages_json)
    setappIDUserImages(appIDUserImages)
    }
    //ゲーム検索＿名前
    const UserInfo_json = localStorage.getItem('UserInfo');
    if(UserInfo_json !== null){
    }
    const UserImages_json = localStorage.getItem('UserImages');
    if(UserImages_json !== null){
   }
   //ゲーム検索＿ID
   const IDuser_json = localStorage.getItem('IDuser');
   if(IDuser_json !== null){
    //  setIDuser(IDuser)
   }
   const IDUserImages_json = localStorage.getItem('IDUserImages');
   if(IDUserImages_json !== null){
  //  setIDUserImages(IDUserImages)
  }
  },[])

  //=====ゲーム検索のためのサジェスト（自分が登録しているゲーム一覧）==========
//   useEffect(() => {
//     if(user){
//     const fetch_usergame = async() => {
//       const gamesQuery = query(collection(db, 'PlayerGames'), where('uuid', '==', user.uid));
//       const gameSnapshot = await getDocs(gamesQuery);
//       // setusergamelist(gameSnapshot.docs.map(doc => ({ ...doc.data() })));
//       setusergamelist(gameSnapshot.docs.map(doc => ({ ...doc.data() })))
//     }
//     fetch_usergame()
//   }
//   },[user])
//やっているゲームのリストを表示する
  const Gamelistview = memo(() => {
    if (Array.isArray(PlayerGames)) {
      return (
        <>
        <div>
          {PlayerGames.map((game, index) => (
          <Link to={`/GameSearch/${game.gameid}`} key={game.gamename+index}>
            <div key={game.gamename+index} className='search_result'  onClick={() => {clickgamename(index,game.gamename,game.gameid)}}>
                {/* <img className='game_img' src={game.gameimgurl} onClick={() => handleClick(game.gameimgurl)}/> */}
                <img className='game_img' alt="" src={game.gameimgurl}/>

                <div className='display_userinfo'>
                <h3 className='usergames_gamename'>{game.gamename}</h3>
                </div>
            </div>
          </Link>
          ))}
          </div>
        </>
      )
  }})
  //=======================アプリユーザー検索======================
  const SearchappUser = async () => {
    const appusername_lower = appusername.toLowerCase()
    try {
        const userQuery = query(
            collection(db, 'UserInfo'),
            orderBy('Username_lower'),
            startAt(appusername_lower),
            endAt(appusername_lower + '\uf8ff'),
            limit(30)
        );
        const userSnapshot = await getDocs(userQuery);
        setappuser(userSnapshot.docs.map(doc => ({ ...doc.data() })));
        localStorage.setItem('appuser',JSON.stringify(userSnapshot.docs.map(doc => ({ ...doc.data() }))))


        const userlist = userSnapshot.docs.map(doc => Object.values(doc.data().uuid).join(''));
        const images = await Promise.all(
            userlist.map(async (uuid) => {
                try {
                    const gameUrl = await getDownloadURL(ref(storage, '/user_images/' + uuid + '.jpg'));
                    return gameUrl;
                } catch (error) {
                    const gameUrl = await getDownloadURL(ref(storage, '/user_images/noimage.jpg'));
                    return gameUrl;
                }
            })
        );
        setappUserImages(images);
        localStorage.setItem('appUserImages',JSON.stringify(images));
    } catch (error) {
        console.error(error);
    }
};

const SearchappIDUser = async () => {
  const appusername_lower = appusername.toLowerCase()
  try {
      const userQuery = query(
          collection(db, 'UserInfo'),
          orderBy('UserID_lower'),
          startAt(appusername_lower),
          endAt(appusername_lower + '\uf8ff'),
          limit(30)
      );
      const userSnapshot = await getDocs(userQuery);
      setappiduser(userSnapshot.docs.map(doc => ({ ...doc.data() })));
      localStorage.setItem('appiduser',JSON.stringify(userSnapshot.docs.map(doc => ({ ...doc.data() }))))


      const userlist = userSnapshot.docs.map(doc => Object.values(doc.data().uuid).join(''));
      const images = await Promise.all(
          userlist.map(async (uuid) => {
              try {
                  const gameUrl = await getDownloadURL(ref(storage, '/user_images/' + uuid + '.jpg'));
                  return gameUrl;
              } catch (error) {
                  const gameUrl = await getDownloadURL(ref(storage, '/user_images/noimage.jpg'));
                  return gameUrl;
              }
          })
      );
      setappIDUserImages(images);
      localStorage.setItem('appIDUserImages',JSON.stringify(images));


  } catch (error) {
      console.error(error);
  }
};

//検索ボタンを押してデータを取ってくる
const search_appuser = async (event) => {
  setloading(true)
    await SearchappUser();
    await SearchappIDUser();
    localStorage.setItem('search_text',appusername)
    setloading(false)
    
};

//クリックしたゲーム名をusestateに格納
const clickgamename = async (index,click_gamename,gameid) => {
}
//ユーザー検索
const handleKeyDown = async(event) => {
  if (event.key === 'Enter') {
    event.preventDefault(); // デフォルトのEnterキー動作を防止する
    search_appuser();

  }
};

const Appuserlength = () => {
  if (appuser.length === 0){
    return(
      <div className='nogatti'>
      <h3>{t('not_found_user')}</h3>
      </div>
    )
  }
}
const Appiduserlength = () => {
  if (appiduser.length === 0){
    return(
      <div className='nogatti'>
      <h3>{t('not_found_user')}</h3>
      </div>
    )
  }
}

//================================================================
  return (
    <div  className='app'> 
    <Helmet>
    <title>IDNect-Search</title>
    </Helmet>

    <div className='app_sidebar'>
    <NewSidebar
      userImageUrl={userImageUrl}
    />
    </div>
    <div className='Search'>

      <style>
      {`
        .searchside {
          font-weight: bold;
          background-color: rgb(231, 231, 231);
        }
      `}
      </style>
      <div className='searchh'>
      <div className='search_body'>
        <h3 className='pagetitle'>{t('search')}</h3>

    <div className='search_body_tab_matrix'>
          <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: '25%',marginLeft: '25%' }}
          >
            <Paper
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center',marginTop: "25px"}}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="ユーザー名 or ID"
              inputProps={{ 'aria-label': 'search google maps' }}
              value={appusername} 
              onChange={(event) => setappusername(event.target.value)}
              onKeyDown={handleKeyDown}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={search_appuser}>
              <SearchIcon />
            </IconButton>
          </Paper>
          
        </Box>
      <div className='newprofile_tab2'>
      <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className='fixed_tab'
        >
          <Tab label={t('search_account')+`${appuser.length+'件'}`} {...a11yProps_select(0)} />
          <Tab label={`ID ${appiduser.length+'件'}`} {...a11yProps_select(1)} />
          <Tab label={t('search_game_account')} {...a11yProps(2)} />
        </Tabs>
      </Box>

      {/* ================アプリユーザー名検索============ */}


      <TabPanel value={value} index={0} >
        {loading === true ? (
          <div className='kurukuru'>
            <CircularProgress className='kuru'/>
          </div>
          ) : (
            <>
                  <Appuserlength />
                {appuser.map((user,index) => (
                  <div key={user.Username+index} className='search_result'>

                      <Link to={`/Profile/${user.uuid}`} >
                      <img className='search_user_img' alt='' src={appuserImages[index]} />
                      </Link>
                      <div className='display_userinfo'>
                      <h3 className='search_username'>{user.Username}</h3>
                      <p className='search_userid'>@{user.UserID}</p>
                      </div>
                  </div>
                            ))}
              </>
          )}
      </TabPanel>

      {/* =============================ID名検索========================== */}

      <TabPanel value={value} index={1} sx={{Padding: 0}}>
        {loading === true ? (
        <div className='kurukuru'>
          <CircularProgress />
        </div>
        ) : (
          <>
          <Appiduserlength/>
          {appiduser.map((user,index) => (

                <div key={user.Username+index} className='search_result'>
                  <Link to={`/Profile/${user.uuid}`} >
                  <img className='search_user_img' alt='' src={appIDUserImages[index]} />
                  </Link>
                  <div className='display_userinfo'>
                  <h3 className='search_username'>{user.Username}</h3>
                  <p className='search_userid'>@{user.UserID}</p>
                  </div>
                </div>
              ))}
            </>
        )}
      </TabPanel>
      <TabPanel value={value} index={2} >
        <Gamelistview/>
      </TabPanel>
      </div>
      </div>
      <div>



        </div>
        </div>

        
    </div>
    </div>
    </div>
  )
};

export default NewSearch;