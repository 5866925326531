import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faDiscord, faTwitch, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import './sns.css';
import { useParams } from 'react-router-dom';
import {  ClickAwayListener, Tooltip } from '@mui/material';
import { t } from 'i18next';

function SNS_2 ({sns_list}){
  const [sns,setsns] = useState();
  const { pk } = useParams();

  const [Discord,setDiscord] = useState()
  const [Tiktok,setTiktok] =useState()
  const [Twitch,setTwitch] =useState()
  const [X,setX] = useState()
  const [Youtube,setYoutube] =useState()

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setsns(sns_list)

  },[sns_list])

  useEffect(() => {
    try{
      setDiscord(sns_list['Discord'])
      setTiktok(sns_list['Tiktok'])
      setTwitch(sns_list['Twitch'])
      setX(sns_list['X'])
      setYoutube(sns_list['Youtube'])
    }catch{
      setDiscord("")
      setTiktok("")
      setTwitch("")
      setX("")
      setYoutube("")
    }
    // setTV(sns_list['TV'])
  },[sns,sns_list])

  //コピー
  const copyToClipboard = async (text) => {
    await global.navigator.clipboard.writeText(text);
    handleTooltipOpen();
  };

  return (
    <div className='sns' key={pk}>
    {/* X-twitter */}
    {X === '' ? (
      <FontAwesomeIcon className='icon gray' icon={faXTwitter} />
    ) : (
      <a target="_blank" href={`https://twitter.com/${X}`} rel="noopener noreferrer">
      <FontAwesomeIcon className='icon x-twitter' icon={faXTwitter} />
      </a>
    )}

    {Discord === '' ? (
      <FontAwesomeIcon className='icon gray' icon={faDiscord} />
    ) : (
    <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={t('copyed')}
        >
          <span>
          <FontAwesomeIcon className='icon discord' onClick={() => copyToClipboard(Discord)} icon={faDiscord} />
          </span>
        </Tooltip>
    </ClickAwayListener>

    )}

    {Twitch === '' ? (
      <FontAwesomeIcon className='icon gray' icon={faTwitch} />
    ) : (
      <a target="_blank" href={`https://www.twitch.tv/${Twitch}`} rel="noopener noreferrer">
      <FontAwesomeIcon className='icon twitch' icon={faTwitch} />
      </a>     
      )}

    {Youtube === '' ? (
      <FontAwesomeIcon className='icon gray' icon={faYoutube} />
    ) : (
      <a target="_blank" href={`https://www.youtube.com/channel/${Youtube}`} rel="noopener noreferrer">
      <FontAwesomeIcon className='icon youtube' icon={faYoutube} />
      </a>
      )}

    {Tiktok === '' ? (
      <FontAwesomeIcon className='icon gray' icon={faTiktok} />
    ) : (
      <a target="_blank" href={`https://www.tiktok.com/@${Tiktok}`} rel="noopener noreferrer">
      <FontAwesomeIcon className='icon tiktok' icon={faTiktok} />
      </a>     
      )}
    </div>
  );
};

export default SNS_2;