import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { createUserWithEmailAndPassword, getAdditionalUserInfo, sendEmailVerification, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import React, { useState } from 'react'
import { auth, SigninWithEmailAndPassword } from '../firebase';
import { t } from 'i18next';

function Remail() {
    const [open, setOpen] = useState(false);
    const [email, setemail] = useState();
    const [password, setpassword] = useState();

    const [massage, setmassage] = useState();





    const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };

      const re_mail_send = async() => {

        try{
            // const user = await SigninWithEmailAndPassword(email, password)
            // const isNewUser = getAdditionalUserInfo(user)
            // console.log(isNewUser)
            // await sendEmailVerification(user.user)
            // setmassage('認証メールを送信しました。')
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            console.log('remail')
            console.log(userCredential)
            const user = userCredential.user;

            // 認証メールを再送信
            await sendEmailVerification(user);
            setmassage('認証メールを送信しました。');
            try{
                if(userCredential.user.emailVerified){
                    
                }else{
                    await signOut(auth);
                }
            }catch(error){
                console.log('エラー')
            }
        }catch(error){
            console.log(error)
            switch (error.code) {
              case 'auth/invalid-email':
                setmassage('auth/invalid-email');
                break;
              case 'auth/user-disabled':
                setmassage('auth/user-disabled');
                break;
              case 'auth/user-not-found':
                setmassage('auth/user-not-found');
                break;
              case 'auth/wrong-password':
                setmassage('auth/wrong-password');
                break;
              case 'auth/email-already-in-use':
                setmassage('auth/email-already-in-use');
                break;
              case 'auth/operation-not-allowed':
                setmassage('auth/operation-not-allowed');
                break;
              case 'auth/weak-password':
                setmassage('auth/weak-password');
                break;
              case 'auth/network-request-failed':
                setmassage('auth/network-request-failed');
                break;
              default:
                setmassage('default');
            }}



      }
  return (
    <div>
            <React.Fragment>
        <div className='remail'>

        <p className='remail_text' onClick={handleClickOpen}>{t('resend_mail')}</p>
        </div>
        <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
            event.preventDefault();
            // handleClose();
            },
        }}
        >
        <DialogTitle className='popname'>{t('resend_mail')}</DialogTitle>
        <DialogContent>
            <DialogContentText>
            {t("required-fields")}
            </DialogContentText>

            <TextField
            autoFocus
            required
            margin="dense"
            id="email"
            name="text"
            label={t('mailaddress')}
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(event) => setemail(event.target.value)}
            />
            <TextField
            autoFocus
            required
            margin="dense"
            id="password"
            name="text"
            label="password"
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(event) => setpassword(event.target.value)}
            />
            {t(massage)}

        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>{t('close')}</Button>
            <Button type='submit' onClick={re_mail_send}>{t('send')}</Button>
        </DialogActions>
        </Dialog>
    </React.Fragment>

    </div>
  )
}

export default Remail;