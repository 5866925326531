import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react'
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

function Login_Reset_password() {
    const [open, setOpen] = useState(false);
    const [email, setemail] = useState();
    const [password, setpassword] = useState();

    const [massage, setmassage] = useState();
    const { t,i18n } = useTranslation();






    const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };

      const reset_password_send_mail = () => {
        sendPasswordResetEmail(auth, email)
        .then(() => {
            // Password reset email sent!
            // ..
            setmassage('パスワード変更用メールを送信しました。')
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
        });
    }


  return (
    <div>
            <React.Fragment>
        <div className='forget_password'>

        <p className='forget_password_text' onClick={handleClickOpen}>{t('forget_password')}</p>
        </div>
        <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
            event.preventDefault();
            // handleClose();
            },
        }}
        >
        <DialogTitle className='popname'>{t('forget_password')}</DialogTitle>
        <DialogContent>
            <DialogContentText>
            {t('required-fields')}
            </DialogContentText>

            <TextField
            autoFocus
            required
            margin="dense"
            id="email"
            name="text"
            label={t('mailaddress')}
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(event) => setemail(event.target.value)}
            />
            {massage}

        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>{t('close')}</Button>
            <Button type='submit' onClick={reset_password_send_mail}>{t('send')}</Button>
        </DialogActions>
        </Dialog>
    </React.Fragment>

    </div>
  )
}

export default Login_Reset_password