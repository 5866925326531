import React from 'react'
import rogo from '../IDnest.jpeg'

import { Link } from 'react-router-dom'
import '../Newcss/Landingpage.css';

import land1 from '../../img/land_1.png';
import land2 from '../../img/land_2.png';
import land3 from '../../img/land_3.png';
import { useTranslation } from 'react-i18next';




function Landingpage() {
  const { t,i18n } = useTranslation();

  return (
    <>
    <div id="header">
        <div className='login_header_buttons'>
        <Link to='/'><img className='profile_rogo' src={rogo}/></Link>
        <h4 className='idnest'>IDNect</h4>
        <Link to='/login'  className='nouser_button'>ログイン・アカウント作成</Link>
        </div>
    </div>
    {/* 700以上 */}
    <div className='Landing'>
      <div>
          <div className='Landing_text'>
              <h2>ゲームIDの管理・共有・検索を簡単に</h2>
          </div>
          <div className='land'>
            <div className='landtext'>
              <h3>プレイしているゲームを管理する</h3>
              <p>10000個以上のゲームを登録可能。登録することで、データが消えた時など、IDが必要な際にも安心。</p>
            </div>
            <img className='land1_img' src={land1}/>
          </div>
          <div className='land'>
          <img className='' src={land2}/>

          <div className='landtext'>
            <h3>ゲームIDの共有</h3>
              <p>フレンドの登録ゲーム・IDを確認可能。IDを聞く・教えるの時間を短縮。自分のURLを教えて共有も可能。</p>
            </div>
          </div>
          <div className='land land3'>
          <div className='landtext'>
            <h3>ゲームIDで人を探す</h3>
              <p>ゲームごとでの検索が可能。ゲーム上の名前やIDだけで人を見つけられる。</p>
              <p>（連絡するには相手がSNSを登録している必要があります。）</p>
            </div>
            <img className='' src={land3}/>
          </div>
      </div>
    </div>
    <div class="land_footer">
          <div className='land_language'>
            <a></a>
            <div>
              <a className='language_select' onClick={() => {i18n.changeLanguage("en"); localStorage.setItem('language', 'en');}}>English</a>
              <a className='language_select' onClick={() => {i18n.changeLanguage("ja");localStorage.setItem('language', 'ja');}}>日本語</a>
            </div>
            <a></a>
          </div>
          <div>
            <ul class="land_footer-links">
              <div>
                <li>お問い合わせ</li>
                <li><Link to='/tos'>利用規約</Link></li>
                <li><Link to='/pp'>プライバシーポリシー</Link></li>
              </div>
            </ul>
          </div>
    </div>
  </>
  )
}

export default Landingpage