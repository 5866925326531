import i18n from 'i18next';
import en from './locales/en/translation.json';
import ja from './locales/ja/translation.json';



import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// const lang = localStorage.getItem('idnestlang')

// i18n
//   .use(HttpApi)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     supportedLngs: ['en', 'ja'],
//     fallbackLng: 'en',
//     detection: {
//       order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
//       caches: ['cookie']
//     },
//     backend: {
//       loadPath: '/locales/{{lng}}/translation.json',
//     },
//     react: {
//       useSuspense: false
//     }
//   });

// export default i18n;
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    ja: {
      translation: ja,
    },
  },
  debug: process.env.NODE_ENV === "development",
  fallbackLng: "en", // デフォルトの言語を設定する
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;